<template>
	<div>
		<b-row>
			<!-- <b-col v-for="i in 12" :key="i" cols="3">
      <b-card class="p-50" no-body style="height: calc((100vh - 19rem) / 3)">
        <h3 class="position-absolute p-1 text-light">{{ i }}</h3>
        <b-img
            :src="'https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/%E9%94%A6%E4%BB%80%E5%9D%8A%E9%A1%B9%E7%9B%AE/%E7%9B%91%E6%8E%A7%E7%94%BB%E9%9D%A2/monitor_' + (i + 1) + '.jpeg'"
            class="w-100 h-100" fluid></b-img>
      </b-card>
    </b-col> -->
			<b-col v-for="i in 12" :key="i" cols="3">
				<b-card class="p-50" no-body style="height: calc((100vh - 19rem) / 3)">
					<div v-if="videourls[i - 1]" class="h-100 pb-1">
						<b-button v-b-tooltip.hover class="btn-icon float-right" size="sm" @click="VideoZoom(i - 1)"> 放大 </b-button>
						<video class="video w-100" style="height: 85%"></video>
						<p class="d-flex justify-content-center align-items-center mb-0">
							<span class="text-sm"> {{ videourls[i - 1].remark }}</span>
						</p>
					</div>
					<div v-else class="d-flex h-100 justify-content-center align-items-center">
						<h5>暂无视频</h5>
					</div>
				</b-card>
			</b-col>
		</b-row>
		<b-modal v-model="showModal" :title="videourls[index].remark" centered hide-footer size="xl">
			<model-loading :is-show="loading_show" :size="1" class="w-100 h-100" />
			<video id="videoAmplification" class="w-100 h-100"></video>
		</b-modal>
	</div>
</template>

<script>
	import flvjs from "flv.js";
	import ModelLoading from "../../../../layout/components/ModelLoading.vue";

	export default {
		name: "index",
		components: {
			ModelLoading,
		},
		data() {
			return {
				showModal: false,
				loading_show: true,
				index: 0,
				videourls: [
					{
						url: "ws://localhost:8866/live?url=rtsp://admin:admin@172.16.0.35/media.amp?streamprofile=Profile1&audio=0",
						remark: "南VIP电梯厅",
					},
					{
						url: "ws://localhost:8866/live?url=rtsp://admin:admin@172.16.0.32/media.amp?streamprofile=Profile1&audio=0",
						remark: "北走廊1",
					},
				],
			};
		},
		mounted() {
			if (flvjs.isSupported) {
				this.videourls.forEach((item, index) => {
					const video = document.querySelectorAll("video")[index];
					this.GetVideo(video, item.url);
				});
			}
		},
		methods: {
			GetVideo(video, url) {
				const flvVideo = flvjs.createPlayer({
					type: "flv",
					url: url,
				});
				flvVideo.attachMediaElement(video);
				flvVideo.load();
				flvVideo.play();
			},
			VideoZoom(i) {
				this.loading_show = true;
				this.index = i;
				this.showModal = true;
				this.$nextTick(() => {
					const video = document.getElementById("videoAmplification");
					video.innerHTML = null;
					this.GetVideo(video, this.videourls[i].url);
					this.loading_show = false;
				});
			},
		},
	};
</script>

<style lang="scss" scoped></style>
